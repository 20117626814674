export const apiKey = process.env.REACT_APP_API_URL;

export const WEB_SITE_STAGE_URL = 'web.foxi-abrechnung.de';
export const WEB_SITE_URL = 'www.foxi-abrechnung.de';
export const APP_STAGE_URL = 'https://stage.foxi-abrechnung.de';
export const APP_URL = 'https://app.foxi-abrechnung.de';

export const GTM_ID = 'G-SY2CF6L6PR';

export const COOKIE_ACCEPTED = 'accepted';
export const COOKIE_DECLINED = 'declined';

export const localStorageKeys = {
  cookieConsent: 'cookieConsent',
};
