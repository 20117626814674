import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import CookieConsentModal from 'components/CookieConsentModal';
import {
  COOKIE_ACCEPTED,
  COOKIE_DECLINED,
  GTM_ID,
  localStorageKeys,
} from 'constants';

import { GlobalStyle } from './GlobalStyles';
import Providers from './Providers';
import Routes from './Routes';

const App = () => {
  const [cookieConsent] = useLocalStorage(localStorageKeys.cookieConsent);

  useEffect(() => {
    if (cookieConsent === COOKIE_ACCEPTED) {
      TagManager.initialize({ gtmId: GTM_ID });
    }
  }, [cookieConsent]);

  const handleAcceptCookies = () => {
    writeStorage(localStorageKeys.cookieConsent, COOKIE_ACCEPTED);
  };

  const handleDeclineCookies = () => {
    writeStorage(localStorageKeys.cookieConsent, COOKIE_DECLINED);
  };

  return (
    <Providers>
      <GlobalStyle />
      <Routes />
      <CookieConsentModal
        isVisible={!cookieConsent}
        onAccept={handleAcceptCookies}
        onDecline={handleDeclineCookies}
      />
    </Providers>
  );
};

export default App;
